.App {
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  background-color: rgb(245, 245, 245);
  height: 100vh;
}

header {
  background-color: #fff;
}

.flex {
  display: flex;
}

.int {
  padding: 3px;
  display: flex;
  align-items: center; 
  justify-content: center;
}

img {
  width: 50%;
}

#headerLeft {
  float:left;
  width: 33%;
  font-size: 20px;
  font-weight: 500;
}

#headerCenter {
  width: 33%;
  display: inline-block;
  margin:0 auto;
}

#headerRight {
  float:right;
  width: 33%;
}

.tab {
  margin: 10px auto;
  padding: 9px;
  border-radius: 5px;
  background: rgb(255, 255, 255);
}

.blur {
  -webkit-box-shadow: 0px 0px 27px 5px rgba(0,0,0,0.18);
  -moz-box-shadow: 0px 0px 27px 5px rgba(117, 117, 117, 0.18);
  box-shadow: 0px 0px 27px 5px rgba(141, 141, 141, 0.18);
}

.tabM {
  width: 80%;
}

.tabS {
  width: 100%;
  margin: 5px 15px;
}

.block2{
  overflow: auto;
  height: 110px;
}

.block3{
  height: 540px;
}

.block4{
  height: 440px;
}

.btn {
  margin: 0 10px;
}

@media (min-width: 600px) {
  .block1{
    height: 420px;
  }
}

.content {
  text-align: left;
  margin: 1px auto;
  width: 88%;
}

thead {
  color: white;
  background-color: orange;
}

.thead-mini {
  font-size: 12px;
}

.row {
  margin: 20px 0;
}

label {
  cursor: pointer;
}

input[type="radio"]{
  margin-right: 3px !important;
}

.btn-calculate{
  position: absolute;
  bottom: 0;
  right: 0;
}

tbody > tr > td {
  font-size: 12px !important;
  font-weight: 600;
  padding: 0.25rem !important;
}

.fixedBox {
  overflow-y:scroll;
}

.f12{
  font-size: 12px;
}

.f15 {
  font-size: 17px !important;
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

select {
  cursor: pointer;
  width: 95%;
  border-radius: 0.25rem;
  height: 31px;
  padding: 1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  width: 95%;
  border-radius: 0.25rem;
  padding: 1px 8px;
}

input[type=date] {
  -moz-appearance: textfield;
  height: 31px;
  width: 94%;
  border-radius: 0.25rem;
  padding: 1px 5px;
  font-size: 13.5px;
}

h6 {
  font-size: 12px !important;
  font-weight: bold !important;
}


label {
  font-size: 11px !important;
}

.listS {
  line-height: 15px !important;
}

.btn-calcular {
  cursor: pointer;
  padding: 5px;
  margin: auto;
  width: 50%;
  color: white;
  border-radius: 10px;
  background-color: orange;
  text-align: center;
}

.btn-calcular-full {
  width: 80%;
  padding: 8px;
}

.table-nm {
  margin: 0 !important;
}

table thead {
  font-size: 13px;
}

button {
  border: 0px;
}

.login-wrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.login-container {
  padding: 2rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 3px 1px -2px rgba(0, 0, 0, 0.12),
              0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background: white;
  width: 100%;
  max-width: 600px;
  text-align: center;
  border-radius: 10px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.login-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.btn-flexicar {
  padding: 3px 10px;
  color: #fff !important;
  background-color: #ff5d0e !important;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  border-radius: 5px;
}

.btn-flexicar:hover {
  background-color: #e04d0c !important;
}

.icon-google {
  vertical-align: middle;
}

.header-login {
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#headerCenter {
  width: 33%;
  display: flex;
  justify-content: center;
}
